.login {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fef6e4;

  form {
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.24);
    background-color: white;
    padding: 40px;

    input {
      font-size: 24px;
      line-height: 1.33333333;
      width: 100%;
      border-width: 0.0625rem;
      padding: 0.1875rem 0.3125rem;
      margin: 5px 10px 20px 0;
      min-height: 40px;
      max-height: none;
      border-color: #c1471c;
      border-radius: 4px;
    }

    button[type="submit"] {
      display: block;
      width: fit-content;
      margin: 10px 20px 0 auto;

      &:hover {
        cursor: pointer;
        background-color: #a9260a;
      }
    }
  }

  .loader-wrapper {
    position: absolute;
    background: rgba(0,0,0,0.17);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login__error-message {
    background: rgba(193,71,28,0.2);
    margin: 0 0 20px;
    padding: 5px 10px;
    font-size: 14px;
    border: 2px solid rgba(193,71,28,0.78);
    border-radius: 2px;
  }
}
