.order-pdf {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $secondary;

  h1 {
    color: $primary;
    margin: 200px 25px 0;
    text-align: center;
    font-weight: 200;
    letter-spacing: 1px;
  }

  .main-logo {
    width: 50px;
    height: 50px;
  }

  .pdf-viewer {
    width: 100%;
    height: 100%;
  }
}
