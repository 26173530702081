.order-overview {
  min-height: 85vh;
  margin: 70px 0;
  padding-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.04);

  &.is-loading {
    padding: 40px 20px;

    h2 {
      display: block;
      font-weight: 500;
      color: #c1471c;
      text-align: center;
      padding: 40px;
      margin:  100px 50px 50px;
      border: 1px solid #c1471c;
      font-size: 22px;
    }
  }

  .order-row-wrapper {
    padding: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.14);
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    margin-bottom: 12px;
  }

  .order-row {
    display: flex;
    align-items: center;
    min-height: 50px;
    justify-content: space-between;
    background-color: white;
  }

  .order-row__time-block-header {
    padding: 10px 15px;
    border: 0;
    font-weight: 600;
    font-size: 24px;
    color: #c1471c;
  }

  .order-info {
    display: flex;
    flex-direction: column;
    width: 350px;
    color: black;
    background-color: lightgrey;

    &__header {
      margin: 0 0 0 30px;
      padding: 30px 10px 8px;
      background-color: rgba(255,255,255,0.86);

      h2 {
        margin: 0 0 5px;
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;

        .highlighted {
          font-weight: 700;
        }
      }
    }

    &__content {
      display: flex;
      align-items: center;
      margin: 0 0 0 30px;
      padding: 0 10px 30px;
      background-color: rgba(255,255,255,0.86);
    }

    &__shipping-type {
      margin: 0 auto;
      font-size: 18px;
      font-weight: 600;
    }

    &__date {
      padding: 0 10px 0 0;
      font-weight: 600;
      font-size: 24px;
    }

    &.frankowitsch {
      background-color: #c1471c;
    }

    &.zustellung {
      background-color: rgba(0,0,0,0.7);
    }

    &.waltendorf {
      background-color: steelblue;
    }

    &.wiener {
      background-color: saddlebrown;
    }
  }

  .order-status {
    display: flex;
    flex-wrap: wrap;
    flex: 0;

    .order-status__wc-status {
      background: lavenderblush;
      width: 100%;
      margin: 0 0 10px;
      padding: 5px 10px;

      &--status {
        font-weight: 600;
        text-transform: uppercase;
      }

      &.wc-completed {
        background-color: lightgreen;
      }

      &.wc-processing {
        background-color: lightcyan;
      }
    }
  }

  .item-counts {
    display: flex;
    align-self: stretch;
    margin: 0;

    .item-counts-category {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 1px;
      padding: 8px;
      width: 54px;
      color: white;
      font-size: 21px;
      font-weight: 600;

      .icon {
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        padding: 12px;
      }

      &.selektionspakete {
        background-color: #9aa37d;

        .icon {
          background-image: url('../img/gift.svg');
        }
      }

      &.patisserie {
        background-color: #c8b2c6;

        .icon {
          background-image: url('../img/cake.svg');
        }
      }

      &.broetchen {
        background-color: #ffb98e;

        .icon {
          background-image: url('../img/bread.svg');
        }
      }

      &.getraenke {
        background-color: #a2b7cd;

        .icon {
          background-image: url('../img/beer.svg');
        }
      }

      &.no-items {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }

  .app-button {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 0 25px 0 0;
    padding: 4px;
    width: 80px;
    height: 80px;
    background-size: 50%;
    background-position: 50% 25%;
    background-repeat: no-repeat;
    background-image: url('../img/edit.svg');
    font-size: 11px;
    letter-spacing: 0;

    &:hover {
      cursor: pointer;
      background-color: #b0360b;
    }
  }

  .edit-button {
    background-image: url('../img/edit.svg');
  }

  .print-button {
    background-image: url('../img/print.svg');
  }
}
