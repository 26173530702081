.qr-scanner {
  max-width: 250px;
  margin: 0 auto;
  width: 100%;
  padding: 45px 15px;
}

.qr-result {
  text-align: center;
  font-weight: 300;
  font-size: 28px;
  color: #c1471c;
}
