.order-detail .order-detail__order-info {
  display: block;
  position: relative;
  padding: 1px;
  background-color: #c1471c;
  color: white;

  .order-info__header {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    margin: 0;
    padding: 10px 20px;
    background-color: #c1471c;
    font-weight: 300;
    font-size: 22px;

    .icon {
      display: inline-block;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 25px;
      height: 25px;
    }

    &--date {
      display: flex;
      align-items: center;
      font-weight: 500;

      .icon {
        background-image: url('../img/clock.svg');
      }
    }

    &--customer-name {
      display: flex;
      align-items: center;

      .icon {
        background-image: url('../img/user.svg');
      }
    }

    &--order-status {
      display: flex;
      align-items: center;

      .icon {
        background-image: url('../img/file.svg');
      }
    }
  }

  .order-info__info-box {
    display: block;
    margin: 5px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.96);
    color: black;

    &.hidden {
      display: none;
    }

    h2 {
      margin: 0;
      color: #c1471c;
    }

    h4 {
      margin: 20px 0;
    }
  }

  .order-info__info-box-content {
    display: flex;
    justify-content: space-evenly;
  }

  .order-info__shipping-info {
    flex: 1;
  }

  .order-info__totals {
    flex: 1.6;
    display: flex;
    flex-wrap: wrap;
    margin-left: 60px;
    border-bottom: 1px solid #c1471c;

    .order-info__totals-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 8px;
      border-top: 1px solid #c1471c;
    }

    .order-info__totals-content {
      margin-left: 20px;
      text-align: right;
    }
  }
}
