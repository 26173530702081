.pickadate-wrapper {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  height: 100vh;
}

.pickadate--root {
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80vh;
  max-width: none;
  max-height: none;
}

.pickadate--header {
  margin: 0 0 40px;
  padding: 20px 15px 0;

  .pickadate--monthAndYear {
    font-size: 40px;
  }

  .pickadate--button {
    fill: #c1471c;
  }
}

.pickadate--button, .pickadate--element {
  background-color: #fef6e4;
}

.pickadate--grid_cell {
  border: 1px solid lightgrey;
}

.pickadate--grid_cell > div {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #c1471c;
  color: white;
  font-size: 32px;
  font-weight: 600;
}

.pickadate--grid_cell__outOfView {
  opacity: 0.25;
  transform: none;
}
