.order-detail__order-status-notes {
  margin: 40px;

  h2 {
    color: #c1471c;
    margin: 10px 20px;
  }

  .order-detail__order-notices {
    background-color: #fef6e4;
    padding: 20px 30px;
    width: 50%;
  }

  .order-detail__order-notice {
    display: flex;
    flex-direction: row;
    margin: 0 0 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-of-type {
      margin: 0;
      border: 0;
    }

    &--header {
      width: 20%;
      font-weight: 300;
      font-size: 0.9em;
      border-right: 1px solid #c1471c;

      .added-by {
        display: block;
        color: grey;
        border-top: 1px solid black;
        margin-bottom: 10px;
        font-size: 0.8em;
      }
    }

    &--content {
      color: black;
      margin-left: 10px;
      font-weight: 400;

      &.highlighted {
        background: #c1471c;
      }
    }
  }

  .order-detail__customer-note {
    background-color: #fef6e4;
    padding: 20px 30px;
    width: 40%;
  }
}
