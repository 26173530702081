.order-detail {
  margin: 110px 0 70px;
  padding-bottom: 40px;

  main {
    margin-bottom: 70px;
  }

  h2 {
    margin: 20px;
    font-size: 26px;
    font-weight: 300;
  }

  .order-detail__root-category {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin: 15px 15px 40px;

    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
      color: white;

      .icon {
        margin-right: 20px;
      }

      .category-item-count {
        margin-left: auto;
      }
    }

    ul {
      display: block;
      width: calc(46% - 8px);
      margin: 10px 2%;
    }

    .icon {
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 40px;
      height: 40px;
    }

    &.selektionspakete {
      border: 5px solid #9aa37d;

      &.is-packed {
        background-color: rgba(154, 163, 125, 0.2);
      }

      .order-detail__root-category--header {
        background-color: #9aa37d;
      }

      .order-detail__subcategory {
        &.is-packed {
          border: 4px solid #9aa37d;
          background-color: rgba(154, 163, 125, 0.4);
        }
      }

      .order-detail__subcategory--header {
        background-color: #9aa37d;
      }

      .order-detail__item {
        border-color: #9aa37d;

        &.is-packed {
          background-color: #9aa37d;
        }
      }

      .icon {
        background-image: url('../img/gift.svg');
      }
    }

    &.patisserie {
      border: 5px solid #c8b2c6;

      &.is-packed {
        background-color: rgba(200, 178, 198, 0.2);
      }

      .order-detail__root-category--header {
        background-color: #c8b2c6;
        color: white;
      }

      .order-detail__subcategory {
        &.is-packed {
          border: 4px solid #c8b2c6;
          background-color: rgba(200, 178, 198, 0.4);
        }
      }

      .order-detail__subcategory--header {
        background-color: #c8b2c6;
      }

      .order-detail__item {
        border-color: #c8b2c6;

        &.is-packed {
          background-color: #c8b2c6;
        }
      }

      .icon {
        background-image: url('../img/cake.svg');
      }
    }

    &.broetchen {
      border: 5px solid #ffb98e;

      &.is-packed {
        background-color: rgba(255, 185, 142, 0.2);
      }

      .order-detail__root-category--header {
        background-color: #ffb98e;
        color: white;
      }

      .order-detail__subcategory {
        &.is-packed {
          border: 4px solid #ffb98e;
          background-color: rgba(255, 185, 142, 0.4);
        }
      }

      .order-detail__subcategory--header {
        background-color: #ffb98e;
      }

      .order-detail__item {
        border-color: #ffb98e;

        &.is-packed {
          background-color: #ffb98e;
        }
      }

      .icon {
        background-image: url('../img/bread.svg');
      }
    }

    &.getraenke {
      border: 5px solid #a2b7cd;

      &.is-packed {
        background-color: rgba(162, 183, 205, 0.2);
      }

      .order-detail__root-category--header {
        background-color: #a2b7cd;
        color: white;
      }

      .order-detail__subcategory {
        &.is-packed {
          border: 4px solid #a2b7cd;
          background-color: rgba(162, 183, 205, 0.4);
        }
      }

      .order-detail__subcategory--header {
        background-color: #a2b7cd;
      }

      .order-detail__item {
        border-color: #a2b7cd;

        &.is-packed {
          background-color: #a2b7cd;
        }
      }

      .icon {
        background-image: url('../img/beer.svg');
      }
    }
  }

  .order-detail__subcategory {
    border: 4px solid #c1471c;
    background-color: #c1471c;

    &.is-packed {
      border: 4px solid green;
      background: none;
    }

    h4 {
      margin: 0;
      font-size: 19px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .order-detail__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 10px;
    padding: 20px;
    border: 2px solid #c1471c;
    background-color: white;
    border-radius: 5px;
    font-weight: 500;
    font-size: 19px;

    &-name {
      flex: 1;
    }

    .custom_product_category {
      font-size: 70%;
      font-weight: 200;
    }

    .item-checkbox {
      display: block;
      background-color: #c1471c;
      border: 2px solid #c1471c;
      border-radius: 1px;
      width: 25px;
      height: 25px;
    }

    &.is-packed .item-checkbox {
      background-image: url('../img/done.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-color: green;
      border: 2px solid green;
    }
  }

  .order-detail__item.is-loading {
    :before {
      z-index: 1;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255,255,255, 0.7)
    }
  }

  .order-detail__footer {
    display: flex;
    justify-content: space-between;
    background-color: #fef6e4;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 20px;
  }
}
