@import './_variables';
@import './_order_overview';
@import './_order_detail';
@import "./_order_detail_order_info";
@import "./_order_detail_order_notes";
@import './_qr_scanner';
@import './_datepicker';
@import './_order-pdf';
@import './_login';

html {
  height: 100%;
}

body {
  height: 100%;
  font: 18px "Open Sans", Verdana, Arial, Helvetica, sans-serif;
  font-weight: 200;
  margin: 0;
}

#root {
  height: 100%;
}

.loader {
  border: 16px solid darkgrey;
  border-top: 16px solid #c1471c;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 150px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


button {
  font: 18px "Open Sans", Verdana, Arial, Helvetica, sans-serif;
  border: 1px solid #c1471c;
  background-color: #c1471c;
  color: white;
  padding: 15px;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 2px;
}

a, a:hover, a:focus, a:visited {
  text-decoration: none;
  color: black;
}


.main-header {
  z-index: 15;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fef6e4;
  padding: 0 10px 0 0;

  h1 {
    margin: 0 25px 5px;
    font-weight: 300;
    font-size: 28px;
    color: #c1471c;
  }
}

.main-logo {
  display: block;
  width: 70px;
  height: 70px;
  background-image: url('../img/fra-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.main-navigation {
  z-index: 10;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fef6e4;
  padding: 0 20px;
  height: 70px;
}

ol, ul {
  padding-left: 30px;
  list-style: none;
  padding-inline-start: 0;
}

li {
  padding: 20px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
}

li a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
}

.line-items li {
  cursor: pointer;
}
